<template>
  <span>
      <GMapAutocomplete ref="gmaps_ref" class="form-control"
                        @place_changed="setPlace($event)"
                        :options="autoCompleteOptions"
      />
      <p v-if="is_healthy">
        {{gmap_event.name}}
      </p>
  </span>
</template>

<script>
export default {
  name: "GMapAutocompleteCustom",
  data() {
    return {
      default_address: '',
      gmap_event: '',
      gmaps_address: '',
      is_healthy: false,
      autoCompleteOptions: {
        componentRestrictions: {
          country: ['IT'],
        },
      },
    }
  },
  methods: {
    setPlace(gmap_event) {
      this.$refs[`gmaps_ref`].$refs.input.value = gmap_event.formatted_address
      this.gmap_event = gmap_event
      this.gmaps_address = gmap_event.formatted_address

      this.is_healthy = !!(this.gmap_event.types.includes('hospital') || this.gmap_event.types.includes('health'));

      if (!this.gmap_event.address_components[0].types.includes('street_number')) {
        this.$swal.fire({
          type: 'error',
          title: 'Oops...',
          text: 'Sembra che non hai inserito nessun numero civico o non si tratta di un civico valido, puoi scegliere di continuare lo stesso o riprovare.',
          showDenyButton: true,
          confirmButtonColor:'#1cb325',
          confirmButtonText: 'Continua senza',
          denyButtonText: `Ok, Inserisco un nuovo indirizzo!`,
        }).then((result) => {
          if (result.isConfirmed) {
            this.$emit('setPlace', this.gmaps_address)
          } else if (result.isDenied) {
            this.$refs[`gmaps_ref`].$refs.input.value = ''
            this.gmap_event = ''
            this.gmaps_address = ''
          }
        })
      } else {
        this.$emit('setPlace', this.gmaps_address)
      }
    },
  }
}
</script>

<style scoped>

</style>